@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  overflow: hidden;
  margin: 0;
  background: url("mavericks.jpg");
  background-size: cover;
  background-repeat: none;
  height: 100vh;
  font-family: "Source Code Pro";
}

#desktop_toolbar {
  padding-left: 15px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  /*background: #FFF;*/
  height: 30px;
  font-size: 20px;
  line-height: 1.5;
}

#fileIcon {
  cursor: pointer;
  position: absolute;
  margin: 50px;
  background: rgba(255, 255, 255, 0);
  /*background: #FFF;*/
  border: none;
  border-radius: 3px;
  padding: 10px;
  /*font-family: 'Open Sans', sans-serif; */
  color: #fff;
  text-shadow: 1px 1px #333;
  /*outline: 0;*/
}
#fileIcon:hover {
  background: rgba(255, 255, 255, 0.4);
}
#fileName {
  color: #fff;
  font-size: 14px;
  text-shadow: 0 0 8px #333;
}

#notepad {
  /*position: absolute;*/
  /*make the rounded edges*/
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  /*effects and sizing*/
  margin: 50px 200px;
  width: 700px;
  box-shadow: 0 0 20px #333;
}

#app_toolbar {
  cursor: move;
  /*position: relative;*/
  background: rgba(255, 255, 255, 0.95);
  padding: 10px 20px;
}
#exit_buttons {
  position: relative;
  float: left;
  width: 33%;
  z-index: 1;
}
#mac_button {
  float: left;
  margin: 4px 16px 0 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  cursor: pointer;
  background: #ff5e51;
}
#menu_items {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.95);
  padding: 4px;
  /*float: left;*/
}
.mi {
  width: 30px;
  height: 30px;
  border-radius: 10%;
  background: none;
  border: none;
  /*box-sizing: border-box;*/
  /*padding: 0;*/
  font-size: 15px;
  /*color: #a6a6a6;*/
  color: #000;
  cursor: pointer;
  outline: none;
}
.mi:hover {
  border: 1px solid #a6a6a6;
  color: #777;
}
.miactive {
  border: 1px solid #a6a6a6;
  border-style: solid;
  border-color: #3d3d3d;
  color: #000;
  box-shadow: inset 0 0 6px #000000;
}
select {
  background: #fcfcfc;
  height: 22px; /*how to make it have 10% border on top and bottom ? */
  cursor: pointer;
}
#right_side {
  /*float: left;*/
  /*margin-right: %;*/
}
.circle {
  float: left;
  margin: 4px 16px 0 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  cursor: pointer;
}
.circle:nth-child(1) {
  background: #ff5e51;
}
.circle:nth-child(1):hover {
  background: #ea1100;
}
.circle:nth-child(2) {
  background: #febb00;
}
.circle:nth-child(2):hover {
  background: #987000;
}
.circle:nth-child(3) {
  background: #18cf37;
}
.circle:nth-child(3):hover {
  background: #0d741f;
}
.clear {
  /*clear: left;*/
  clear: both;
}
#app_title {
  z-index: 0;
  color: #888;
  float: left;
  width: 33%;
  text-align: center;
  margin-left: -10px;
}
.main {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 50px 40px;
  background: #f9f8f7;
  width: 100%;
}
.content {
  margin-top: 40px;
  position: relative;
  /*overflow: none;*/
}

/*select > option {
   font-size: 15px;
   background: #fafafa;
}
*/
